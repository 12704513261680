function PostInlineEdit(opts) {
	var defaults = {
		id: false,
		container: false,
		displaytype: "full",
		wrap: true,
        pageid: false
	};
	
	this.inlineId = PostInlineEdit.nextId++;
		
	this.editor = false;
	this.options = $.extend({}, defaults, opts);
	
	if (!this.options.id || !this.options.container) {
		console.log("Invalid options");
		return;
	}
	
	this.id = this.options.id;
	this.pageid = this.options.pageid;
	this.cont = this.options.container;
    this.admin = options.admin;
	this.parsed = false;
	this.raw = false;
    this.removed = false;
    $(this.cont).css({"position": "relative"});
};

PostInlineEdit.nextId = 0;

PostInlineEdit.prototype = {
	constructor: PostInlineEdit,
	
	startEdit: async function() {
		var c = this.cont;
		$(c).animate({"opacity": "0.5"}, "fast");
		var raw = await this.loadRaw();
		if (!raw) {
			return false;
		}
		$(c).stop();
		$(c).css({"opacity": "1"});
		this.raw = raw;
		if (this.options.wrap) {
			$(c).html(`<div class="container"><div id="inline_edit_${this.inlineId}">${raw}</div></div>`);
		} else {
			$(c).html(`<div><div id="inline_edit_${this.inlineId}">${raw}</div></div>`);
		}
		this.createEditor();
		return true;
	},
	
	cancelEdit: async function() {
		var c = this.cont;
		if (!this.parsed) {
			this.parsed = await this.loadParsed();
		}
		
		if (!this.parsed) {
			return false;
		}
		tinymce.remove("#inline_edit_"+this.inlineId);
		$(c).html(this.parsed);		
		updategrids();
		return true;
	},
	
	saveEdit: async function() {		
		var c = this.cont;
		this.raw = tinymce.get("inline_edit_"+this.inlineId).getContent();
		
		try {
			var parsed = await $.ajax({
				url: "api/posts/savecontent",
				data: {
					post: this.id,
					content: this.raw,					
					displaytype: this.options.displaytype
				},
				type: "POST"
			});
			
			tinymce.remove("#inline_edit_"+this.inlineId);
			
			if (!"parsed" in parsed) {
				new Notification().error("Post konnte nicht korrekt gespeichert werden");
			} else {
				this.parsed = parsed.parsed;
				this.updateContent();
			}			
		} catch (e) {
			new Notification().error("Post konnte nicht gespeichert werden");
			console.log(e);
			return false;
		}
		
		return true;		
	},
    
    updateContent: function() {
        if (this.removed) {
            $(this.cont).animate({"opacity": "0.1"}, "slow");
        } else {            
            $(this.cont).animate({"opacity": "1"}, "slow");
        }
        
        $(this.cont).html(this.parsed);
        updategrids();
    },
    
    
    editCategories: async function() {
        var dlg = new Dialog();
        var that = this;
        dlg.loading("Bitte warten", "Kategorien werden geladen");
        
        var categories = await this.loadCategories();
        dlg.close();
        if (!categories) {
            return false;
        }
        dlg = new Dialog();
        html = this.buildCategories(categories, true);
        
        var cancel = function() {
            dlg.close();
        }
        
        var save = async function() {        
            var cats = [];
            dlg.getNode().find("input[type=checkbox]:checked").each(function(id, elem) {
                cats.push($(elem).val());
            });
            dlg.close();
            dlg = new Dialog();
            dlg.loading("Bitte warten", "Kategorien werden gespeichert");
            var info = await that.saveCategories(cats);
            if (info) {
                that.removed = !info.showonpage;
                that.parsed = info.parsed;
                that.updateContent();
            }
            dlg.close();
        }
        
        dlg.build()
            .addTitle("Kategorien wählen")
            .addHtml(html)
            .addButton("Speichern", "save_cats", save)
            .addButton("Abbrechen", "cancel_cats", cancel)
            .onEscape(cancel)
            .onEnter(save)
            .show();
    },
    
    buildCategories: function(categories, base = false) {
        if (categories.length == 0) {
            return "";
        }
    
        var cls = base ? "post_inline_edit_cat_list_base" : "post_inline_edit_cat_list";
        var html = `
            <ul class="${cls}">`;
        for (var c of categories) {
            html += `
            <li>
                <label>
                    <input id="post_inline_edit_cat_${c.id}" type="checkbox" 
                        class="checkbox_large filled-in" name="${c.name}" value="${c.id}" ${c.selected ? " checked" : ""}/>
                    <span>${c.name}</span>
                </label>
                ${this.buildCategories(c.children)}
            </li>`;
        }
        
        html += '</ul>';
        return html;
    },
	
	loadParsed: async function() {
		try {
			var parsed = await $.ajax({
				url: "api/posts/parsed",
				data: {
					post: this.id,
					displaytype: this.options.displaytype
				}
			});
			
			if ("parsed" in parsed) {
				return parsed.parsed;
			}				
		} catch (e) {
			new Notification().error("Post konnte nicht geladen werden");
			return false;
		}
		return false;
	},
	
	loadRaw: async function() {
		try {
			var raw = await $.ajax({
				url: "api/posts/raw",
				data: {
					post: this.id
				}
			});
			
			if ("raw" in raw) {
				return raw.raw;
			}				
		} catch (e) {
			new Notification().error("Post konnte nicht geladen werden");
			return false;
		}
		return false;		
	},
	
	loadCategories: async function() {
		try {
			var raw = await $.ajax({
				url: "api/posts/getcategories",
				data: {
					post: this.id
				}
			});
			
			if ("categories" in raw) {
				return raw.categories;
			}				
		} catch (e) {
			new Notification().error("Post-Kategorien konnten nicht geladen werden");
			return false;
		}
		return false;		
	},
    
    saveCategories: async function(categories) {
		try {
			var raw = await $.ajax({
				url: "api/posts/setcategories",
				data: {
					post: this.id,
                    categories: categories,
                    page: this.pageid,
                    displaytype: this.options.displaytype
				}
			});
			
            if ("info" in raw) {
                return raw.info;
            }
		} catch (e) {
			new Notification().error("Post-Kategorien konnten nicht geladen werden");
			return false;
		}
		return false;		
	},
	
	createEditor: async function() {
		this.editor = await tinymce.init({
			selector: "#inline_edit_"+this.inlineId,		
			relative_urls : true,
			valid_elements : '*[*]',
			inline: true,
			body_class: "editor-body",
			document_base_url : "/",
			convert_urls : false,
			toolbar: [
				'undo redo | formatselect forecolor blockquote | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | visualblocks visualchars fullscreen piCode',
				'hr | bullist numlist outdent indent | table',
				PageItEditor.toolbar
			],
			menubar: false,
			menu: false,
			plugins: [
				'advlist autolink link image lists charmap preview hr anchor pagebreak',
				'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
				'table directionality paste',
				PageItEditor.customPlugins.join(" ")
			],
			color_map: PageItEditor.colorMap
		});
		
		tinymce.get("inline_edit_"+this.inlineId).focus();
		updategrids();
	}
};