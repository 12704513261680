class PostEditor {
    static editorId = 0;
    static instances = [];

    static getInstance(selector) {
        let parent = $(selector);
        for (let instance of PostEditor.instances) {
            if (parent.is(instance.container)) {
                return instance;
            }
        }
        return false;
    }

    constructor(container, config = null, options = {}) {
        this.editorId = `pi-editor-${PostEditor.editorId++}`;
        this.editorSelector = `#${this.editorId}`;
        PostEditor.instances.push(this);
        this.container = container;

        if (config === null) {
            if (this.container.children("textarea").length > 0) {
                try {
                    config = JSON.parse(this.container.children("textarea").val());
                } catch (e) {
                    if (this.container.children("textarea").val().trim() !== "") {
                        new Notification().error("Inhalt konnte nicht geladen werden");
                        console.error(e);
                    }
                }
            }
        }
        this.config = config;

        this.editorRoot = null;
        this.buildingBlocks = false;        
        this.sortedBuildingBlocks = [];

        this.postPreviewClass = options.postPreviewClass || "post";

        this.id = 0;
        this.history = [];
        this.history_position = 0;
        this.cache = false;
        this.escapeHandlerFunction = this.escapeHandler.bind(this);

        this.previewCache = {};
        this.data = {};

        this.lastSavedState = false;

        if (!$(".pageit-admin-editor-overlay").length) {
            $("body").append(`<div class="pageit-admin-editor-overlay"></div>`);
        }        
        this.overlay = $(".pageit-admin-editor-overlay");

        window.addEventListener("beforeunload", this.pageNavigationHandler.bind(this));
        
        this.init();
    }

    destroy() {
        this.hideOverlay();
        this.container.find("*").off();
        this.container.html("");
        for (let instance_index in PostEditor.instances) {
            let instance = PostEditor.instances[instance_index];
            if (instance.editorId === this.editorId) {
                PostEditor.instances.splice(instance_index, 1);
                break;
            }
        }
    }

    escapeHandler(e) {
        if (e.key === "Escape") {
            e.preventDefault();
            e.stopPropagation();
            this.hideOverlay();
        }
    }

    pageNavigationHandler(e) {
        let currentState = JSON.stringify(this.export());

        if (currentState === JSON.stringify(this.lastSavedState)) {                
            return;
        }
        e.preventDefault();
        e.returnValue = "";
    }

    init() {
        let that = this;

        $(this.container).html(`
            <div class="pageit-admin-editor" id="${this.editorId}">
                <div class="control">
                    <div class="icon">
                        <i class="material-icons">mode</i>
                    </div>
                    <div class="title">
                        PageIt Editor
                    </div>
                    <div class="config">
                        <div class="action minimize_all" title="Alle Minimieren">
                            <i class="material-icons">minimize</i>
                        </div>
                        <div class="action maximize_all" title="Alle Maximieren">
                            <i class="material-icons">maximize</i>
                        </div>
                        <div class="action undo disabled" title="Rückgängig">
                            <i class="material-icons">undo</i>
                        </div>
                        <div class="action redo disabled" title="Wiederherstellen">
                            <i class="material-icons">redo</i>
                        </div>                        
                        <div class="action import" title="Import">
                            <i class="material-icons">file_download</i>
                        </div>
                        <div class="action export" title="Export">
                            <i class="material-icons">file_upload</i>
                        </div>
                        <div class="action paste" title="Einfügen">
                            <i class="material-icons">content_paste</i>
                        </div>
                        <div class="action appender highlight" title="Block erstellen">
                            <i class="material-icons">add_circle</i>
                        </div>
                        <div class="action show-preview highlight" title="Vorschau">
                            <i class="material-icons">preview</i>
                        </div>
                    </div>
                </div>
                <div class="children">

                </div>
            </div>
        `);

        this.initSortable();

        this.buildingBlocks = false;
        this.editorRoot = $(this.container).find(".pageit-admin-editor");
        this.loadAvailableBlocks(function() {
            if (that.config === null) {                
                that.addBlock(that.editorRoot, "html", {}, []);
                that.pushHistory();
            } else {
                that.history = [that.config];
                that.applyHistory(0);
            }
            that.lastSavedState = that.export();
        });
        
        this.initEvents();
    }

    saved() {
        this.lastSavedState = this.export();
    }

    hash(str) {
        let hash = 0;
        for (let i = 0, len = str.length; i < len; i++) {
            let chr = str.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    getPreview(block, callback, allowCache=true) {
        let data = this.getData(block, undefined, undefined, true);
        let hash = this.hash(JSON.stringify(data));
        let preview = "";
        let that = this;
        if (!allowCache || !(hash in this.previewCache)) {
            // Request new preview
            $.ajax({
                "url": "api/layouts/get_preview",
                "type": "post",
                "data": {
                    "config": data
                },
                success: function(response) { 
                    if (typeof(response) === "object") {
                        preview = response.preview;
                    } else {
                        preview = response;
                    }
                    that.previewCache[hash] = preview;
                    callback(preview);
                },
                error: function(e) {                    
                    new Notification().error("Vorschau konnte nicht geladen werden");
                    console.error(e);
                    callback(preview);
                }
            });
        } else {
            preview = this.previewCache[hash];
            callback(preview);
        }
    }

    addData(block, key, value) {
        let block_id = block.attr("id");
        if (!(block_id in this.data)) {
            this.data[block_id] = {};
        }
        this.data[block_id][key] = value;
    }

    getDataValue(block, key = undefined, default_value = undefined) {
        let data = this.getData(block, key, default_value);
        if (data === undefined) {
            return default_value;
        }

        if (key !== undefined) {
            if ("value" in data) {
                return data["value"];
            }
            return default_value;
        } else {
            let value_data = {};
            for (let data_key in data) {
                value_data[data_key] = data[data_key]["value"];
            }
            return value_data;
        }
    }

    resolveExposedOption(block, option) {
        if (option["is_exposed"]) {
            let exposed_name = option["exposed_name"];
            parent = this.getParentBlock(block);
            if (!parent) {
                return option;
            }
            let parent_data = this.getData(parent, exposed_name, undefined, true);            
            if (parent_data !== undefined) {
                option["exposed_value"] = parent_data["value"];
            }
        }
        return option;
    }

    getData(block, key = undefined, default_value = undefined, fill_in_exposed_options = false) {
        let block_id = block.attr("id");

        if (block_id in this.data) {
            if (key === undefined) {
                // Return all data
                let options = Object.assign({}, this.data[block_id]);
                if (fill_in_exposed_options) {
                    for (let key in options) {
                        let option = options[key];
                        options[key] = this.resolveExposedOption(block, option);
                    }
                }
                return options;
            }
            if (key in this.data[block_id]) {                            
                let option = this.data[block_id][key];
                option = this.resolveExposedOption(block,  option);
                return option;                
            }
        }
        if (key === undefined) {
            // Return empty object
            return {};
        }

        return default_value;
    }

    pushHistory(content = false) {
        this.history.splice(this.history_position + 1);
        if (content === false) {
            content = this.export();
        }
        this.history.push(content);
        this.history_position = this.history.length - 1;
        this.updateHistoryButtons();
    }

    applyHistory(position) {
        let state = this.history[position];
        this.history_position = position;
        // Clear editor
        for (let child of this.getChildrenBlocks(this.editorRoot)) {
            this.removeBlock(child, false);
        }
        for (let child_data of state["children"]) {
            this.addBlock(this.editorRoot, child_data["block-name"], child_data["config"], child_data["children"], child_data["id"]);
        }
        this.updateHistoryButtons();
    }

    updateHistoryButtons() {        
        $(this.editorRoot).find(".undo").toggleClass("disabled", !this.undoAvailable());
        $(this.editorRoot).find(".redo").toggleClass("disabled", !this.redoAvailable());
    }

    undo() {
        if (!this.undoAvailable()) {
            new Notification().error("'Rückgängig' nicht verfügbar");
            return;
        }
        this.applyHistory(this.history_position - 1);
    }

    redo() {
        if (!this.redoAvailable()) {
            new Notification().error("'Wiederherstellen' nicht verfügbar");
            return;
        }
        this.applyHistory(this.history_position + 1);
    }

    undoAvailable() {
        return this.history_position > 0;
    }

    redoAvailable() {
        return this.history_position + 1 < this.history.length;
    }

    removeData(block, key) {
        let block_id = block.attr("id");
        if (block_id in this.data) {
            if (key in this.data[block_id]) {
                delete this.data[block_id][key];
            }
        }
    }

    initSortable() {
        let that = this;
        $(this.container).find(".children").sortable({
            "connectWith": ".children",
            "handle": ".move",
            "placeholder": "building-block-placeholder",
            "update": function(event, ui) {
                that.pushHistory();
            }
        });
    }

    addBlock(parent, block_name, config, children_data, block_id = false) {
        let block_data = this.buildingBlocks[block_name];        
        let full_id = block_id;
        if (!block_id) {
            let id = this.id++;
            full_id = `${this.editorId}-block-${id}`;
        } else {
            // Extract original id and adjust
            let split_id = full_id.split("-");
            let int_id = Number.parseInt(split_id[split_id.length - 1]);
            if (!isNaN(int_id)) {
                this.id = Math.max(int_id + 1, this.id);
            }
        }

        let allows_children = block_data["allows_children"];
        let provides_preview = block_data["provides_preview"];
        let appender = "";
        let children = "";
        let preview = "";
        let paster = "";
        let dissolver = "";
        let replacer = "";
        let editor = `
            <div class="action edit highlight" title="Konfigurieren">
                <i class="material-icons">build_circle</i>
            </div>`;
        let mover = `
            <div class="action move" title="Verschieben">
                <i class="material-icons">drag_indicator</i>
            </div>`;
        let upMover = `
            <div class="action move-up" title="Nach oben">
                <i class="material-icons">arrow_upward</i>
            </div>`;
        let downMover = `
            <div class="action move-down" title="Nach unten">
                <i class="material-icons">arrow_downward</i>
            </div>`;
        let copier = `
            <div class="action copy" title="Kopieren">
                <i class="material-icons">copy_all</i>
            </div>`;
        let duplicator = `
            <div class="action duplicate" title="Duplizieren">
                <i class="material-icons">content_copy</i>
            </div>`;
        let cutter = `
            <div class="action cut" title="Ausschneiden">
                <i class="material-icons">content_cut</i>
            </div>`;
        let deleter = `
            <div class="action delete" title="Löschen">
                <i class="material-icons">delete</i>
            </div>`;

        let minimizer = `
            <div class="action minimize" title="Minimieren">
                <i class="material-icons">minimize</i>
            </div>`;

        let maximizer = `
            <div class="action maximize hidden" title="Maximieren">
                <i class="material-icons">maximize</i>
            </div>`;

        
        if (allows_children) {
            appender = `
                <div class="action highlight appender" title="Neues Unterelement">
                    <i class="material-icons">add_circle</i>
                </div>`;
            children = `
                <div class="children">

                </div>
            `;
            paster = `
                <div class="action paste" title="Einfügen">
                    <i class="material-icons">content_paste</i>
                </div>`;
            dissolver = `
                <div class="action dissolve" title="Auflösen">
                    <i class="material-icons">data_array</i>
                </div>`;
            replacer = `
                <div class="action replace" title="Ersetzen">
                    <i class="material-icons">sync</i>
                </div>`;
        }

        if (provides_preview) {
            preview = `
                <div class="preview">

                </div>
            `;
        }

        let icon = `
            <div class="icon">
                <i class="material-icons">${block_data["icon"]}</i>
            </div>
        `;
        let title = `
            <div class="title">
                ${block_data["readable_name"]}
            </div>
        `;

        $(parent).children(".children").append(`
            <div class="pageit-block ${block_name}" id="${full_id}">
                <div class="configurator">
                    ${icon}
                    ${title}
                    <div class="config">
                        ${minimizer}
                        ${maximizer}
                        ${mover}
                        ${upMover}
                        ${downMover}
                        ${cutter}
                        ${paster}
                        ${copier}
                        ${duplicator}
                        ${replacer}
                        ${dissolver}
                        ${deleter}
                        ${editor}
                        ${appender}
                    </div>
                </div>
                ${preview}
                ${children}
            </div>
        `);
        let layout = $(`#${full_id}`);
        this.addData(layout, "block-name", {
            "is_exposed": false,
            "exposed_name": false,
            "value": block_name
        });
        for (let key in config) {
            this.addData(layout, key, config[key]);
        }

        if (allows_children && children_data.length) {
            for (let child_data of children_data) {
                let child_id = child_data["id"];
                if (!block_id || !child_id) {
                    child_id = false;
                }
                this.addBlock(layout, child_data["block-name"], child_data["config"], child_data["children"], child_id);
            }
        }

        this.initSortable();
        if (provides_preview) {
            this.updatePreview(layout);            
        }
        return layout;
    }

    export(root = false) {
        let that = this;

        if (!root) {
            root = this.editorRoot;
        }
        
        let data = this.getData(root);        
        let block_name = this.getDataValue(root, "block-name");
        let block_id = root.attr("id");

        let children = [];
        for (let child of this.getChildrenBlocks(root)) {
            let child_data = that.export(child);
            children.push(child_data);
        }

        return {
            "block-name": block_name,
            "id": block_id,
            "config": data,
            "children": children
        };
    }

    initEvents() {
        let that = this;
        // Editor events
        $(this.editorSelector).off("click", ".undo");
        $(this.editorSelector).on("click", ".undo", function() {
            that.undo();
        });
        
        $(this.editorSelector).off("click", ".redo");
        $(this.editorSelector).on("click", ".redo", function() {
            that.redo();
        });
        
        $(this.editorSelector).off("click", ".import");
        $(this.editorSelector).on("click", ".import", async function() {
            let config_text = await navigator.clipboard.readText();
            try {
                let config = JSON.parse(config_text);
                that.pushHistory(config);
                that.applyHistory(that.history_position);                
                new Notification().success("Konfiguration aus Zwischenablage angewandt");
            } catch (e) {
                new Notification().error("Import fehlgeschlagen");
            }
        });
        
        $(this.editorSelector).off("click", ".export");
        $(this.editorSelector).on("click", ".export", function() {
            let config = that.export();
            let config_text = JSON.stringify(config);
            navigator.clipboard.writeText(config_text);
            new Notification().success("Konfiguration in Zwischenablage kopiert");
        });
        
        $(this.editorSelector).off("click", ".show-preview");
        $(this.editorSelector).on("click", ".show-preview", function() {
            that.showFullPreview();
        });

        $(this.editorSelector).off("click", ".minimize_all");
        $(this.editorSelector).on("click", ".minimize_all", function() {
            for (let child of that.getChildrenBlocks(that.editorRoot)) {
                that.minimizeBlock(child);
            }
        });
        $(this.editorSelector).off("click", ".maximize_all");
        $(this.editorSelector).on("click", ".maximize_all", function() {
            for (let child of that.getChildrenBlocks(that.editorRoot)) {
                that.maximizeBlock(child);
            }
        });


        // Combined
        $(this.editorSelector).off("click", ".appender");
        $(this.editorSelector).on("click", ".appender", function() {
            that.appendBlock($(this).parent().parent().parent());
        });

        // Block events

        $(this.editorSelector).off("click", ".edit");
        $(this.editorSelector).on("click", ".edit", function() {
            that.configureBlock($(this).parent().parent().parent());
        });
        $(this.editorSelector).off("dblclick", ".configurator");
        $(this.editorSelector).on("dblclick", ".configurator", function(e) {
            if ($(e.target).hasClass("action")) {
                return;
            }
            that.configureBlock($(this).parent());
        });

        $(this.editorSelector).off("click", ".move-up");
        $(this.editorSelector).on("click", ".move-up", function() {
            let block = $(this).parent().parent().parent();
            let prev = that.getPreviousBlock(block);
            if (!prev) {
                return;
            }
            block.after(prev);
        });

        $(this.editorSelector).off("click", ".move-down");
        $(this.editorSelector).on("click", ".move-down", function() {
            let block = $(this).parent().parent().parent();
            let next = that.getNextBlock(block);
            if (!next) {
                return;
            }
            block.before(next);
        });

        $(this.editorSelector).off("click", ".delete");
        $(this.editorSelector).on("click", ".delete", function() {
            that.removeBlock($(this).parent().parent().parent());
        });

        $(this.editorSelector).off("click", ".cut");
        $(this.editorSelector).on("click", ".cut", function() {
            that.cutBlock($(this).parent().parent().parent());
        });

        $(this.editorSelector).off("click", ".copy");
        $(this.editorSelector).on("click", ".copy", function() {
            that.copyBlock($(this).parent().parent().parent());
        });

        $(this.editorSelector).off("click", ".duplicate");
        $(this.editorSelector).on("click", ".duplicate", function() {
            that.duplicateBlock($(this).parent().parent().parent());
        });

        $(this.editorSelector).off("click", ".paste");
        $(this.editorSelector).on("click", ".paste", function() {
            that.pasteBlock($(this).parent().parent().parent());
        });
        
        $(this.editorSelector).off("click", ".replace");
        $(this.editorSelector).on("click", ".replace", function() {
            that.replaceBlock($(this).parent().parent().parent());
        });
        
        $(this.editorSelector).off("click", ".dissolve");
        $(this.editorSelector).on("click", ".dissolve", function() {
            that.dissolveBlock($(this).parent().parent().parent());
        });
        
        $(this.editorSelector).off("click", ".minimize");
        $(this.editorSelector).on("click", ".minimize", function() {
            that.minimizeBlock($(this).parent().parent().parent());
        });
        
        $(this.editorSelector).off("click", ".maximize");
        $(this.editorSelector).on("click", ".maximize", function() {
            that.maximizeBlock($(this).parent().parent().parent());
        });
    }

    updatePreview(block) {
        let that = this;
        if (block.children(".preview").length === 0) {
            return;
        }
        let iframe_preview = this.buildingBlocks[this.getBlockType(block)]["preview_as_iframe"];

        if (iframe_preview) {
            // Check if iframe exists
            let iframeId = block.attr("id") + "-preview-iframe";
            let resizer = false;
            if ($(`#${iframeId}`).length) {
                // iframe exists
                if ("iFrameResizer" in document.getElementById(iframeId)) {
                    resizer = document.getElementById(iframeId).iFrameResizer;
                }
            } else {
                let iframe = `<iframe name="${iframeId}" id="${iframeId}" style="width: 100%; border: 0; overflow-y: scroll;"></iframe>`;
                block.children(".preview").html(iframe);
            }
            if (!resizer) {
                iFrameResize({"maxHeight": 500, "checkOrigin": false, "scrolling": true}, `#${iframeId}`);
                resizer = document.getElementById(iframeId).iFrameResizer;
            }
            // Submit form
            let formId = block.attr("id") + "-preview-from";
            block.children(".preview").append(`
                <form action="api/layouts/get_preview" method="post" target="${iframeId}" id="${formId}">
                    <textarea style="display: none;" name="config">${JSON.stringify(this.getData(block, undefined, undefined, true))}</textarea>
                </form>
            `); 
            let iframe = $(`#${iframeId}`);
            iframe.data("loadExpected", true);
            iframe.off("load");
            iframe.on("load", function() {
                if (!($(this).data("loadExpected"))) {
                    let block = that.getBlock($(this));
                    if (!block) {
                        console.error("No block found for:");
                        return;
                    }
                    that.updatePreview(block);                    
                } else {                    
                    $(this).data("loadExpected", false);
                }
            });
            $(`#${formId}`).trigger("submit");
            $(`#${formId}`).remove("");
        } else {
            this.getPreview(block, function(preview) {
                block.children(".preview").html(preview);
            });
        }

        // Update previews when any child depends on this block
        if (Object.keys(this.getChildrenExposedOptions(block)).length > 0) {
            for (let child of this.getChildrenBlocks(block)) {
                this.updatePreview(block);
            }
        }
    }

    selectBlock(filter = false) {
        let content = `
            <div>`;

        let last_group = false;

        for (let name of this.sortedBuildingBlocks) {            
            let element = this.buildingBlocks[name];
            if (element["hidden"]) {
                continue;
            }
            
            let block_matches_filter = true;
            if (filter !== false) {
                for (let key in filter) {
                    if (element[key] !== filter[key]) {
                        block_matches_filter = false;
                        break;
                    }
                }
            }
            if (!block_matches_filter) {
                continue;
            }

            let element_group = element.group;
            if (element_group !== last_group) {
                last_group = element_group;
                content += `
                </div>
                <div class="buildingBlockGroup">${element_group}</div>
                <div class="buildingBlockSelection">`;
            }

            content += `
                <div class="buildingBlockSelector" data-blockName="${element.name}">
                    <div class="icon">
                        <i class="material-icons">${element.icon}</i>
                    </div>
                    <div class="info">
                        <span class="name">${element.readable_name}</span>
                        <span class="description">${element.description}</span>
                    </div>
                </div>
            `;
        }

        content += `
            </div>`;
        this.showOverlay("Baustein auswählen", content);
    }

    appendBlock(parent) {
        let that = this;

        if (parent.children(".children").length === 0) {
            new Notification().error("Dieser Block unterstützt keine Unterelemente");
            return;
        }

        this.selectBlock();

        $("body").off("click", ".buildingBlockSelector");
        $("body").on("click", ".buildingBlockSelector", function() {
            let block_name = $(this).attr("data-blockName");            
            let block = that.addBlock(parent, block_name, {}, []);
            that.pushHistory();     
            that.configureBlock(block);
        });
    }

    configureBlock(block) {
        this.hideOverlay();
        let that = this;
        // let data = this.getData(block);
        let block_data = this.buildingBlocks[this.getBlockType(block)];
        let configurator = new BlockConfigurator(this);
        this.showOverlay(block_data["readable_name"] + " konfigurieren", "", false);
        configurator.setOnApplyCallback(function(configuration) {
            that.applyBlockConfiguration(block, configuration);
            that.hideOverlay();            
        });
        configurator.setOnCancelCallback(function() {
            that.hideOverlay();
        });
        configurator.show(this.overlay.children(".overlay-wrapper").children(".overlay-content"), block);
    }

    applyBlockConfiguration(block, configuration) {        
        for (let key in configuration) {
            let value = configuration[key];
            this.addData(block, key, value);
        }
        this.pushHistory();
        this.updatePreview(block);
    }

    removeBlock(block, push_history = true) {
        let block_id = block.attr("id");
        if (block_id === this.editorRoot.attr("id")) {
            new Notification().error("Das Wurzelelement kann nicht entfernt werden");
            return;
        }
        // Delete children recursively (for cleanup)
        for (let child of this.getChildrenBlocks(block)) {
            this.removeBlock(child, false);
        }
        if (block_id in this.data) {
            delete this.data[block_id];
        }
        block.remove();
        if (push_history) {
            this.pushHistory();
        }
    }

    copyBlock(block) {
        this.cache = this.export(block);
    }

    duplicateBlock(block) {
        let block_data = this.export(block);
        let block_parent = this.getParentBlock(block);
        if (!block_parent) {
            block_parent = this.editorRoot;
        }
        let copied_block = this.addBlock(block_parent, block_data["block-name"], block_data["config"], block_data["children"]);
        // Move directly after the original block
        copied_block.insertAfter(block);
        this.pushHistory();
    }
    
    pasteBlock(block) {
        if (!this.cache) {
            new Notification().error("Kein Element zum Einfügen vorhanden");
            return;
        }
        this.addBlock(block, this.cache["block-name"], this.cache["config"], this.cache["children"]);
        this.pushHistory();
    }

    cutBlock(block) {
        this.copyBlock(block);
        this.removeBlock(block);
    }

    dissolveBlock(block) {
        let parent = this.getParentBlock(block, true);
        if (!parent) {
            new Notification().error("Block kann nicht aufgelöst werden");
            return;
        }
        for (let children of this.getChildrenBlocks(block)) {
            this.moveBlock(children, parent);
        }
        this.removeBlock(block, true);
    }

    replaceBlock(block) {
        let that = this;

        this.selectBlock({"allows_children": true});
        let config = this.getData(block);

        $("body").off("click", ".buildingBlockSelector");
        $("body").on("click", ".buildingBlockSelector", function() {
            that.hideOverlay();
            
            let block_name = $(this).attr("data-blockName");
            let block_config = that.buildingBlocks[block_name];
            let new_config = {};
            for (let key in config) {
                if (key in block_config["configuration_options"]) {
                    new_config[key] = config[key];
                }
            }
            let new_block = that.addBlock(that.editorRoot, block_name, new_config, []);

            // Move children
            for (let child_block of that.getChildrenBlocks(block)) {
                child_block.detach().appendTo(new_block.children(".children"));
            }
            // Replace original block
            block.replaceWith(new_block);
            that.pushHistory();     
        });

    }

    moveBlock(block, new_parent) {
        if (!this.allowsChildren(new_parent)) {
            new Notification().error("Block kann nicht verschoben werden");
            return;
        }
        block.appendTo(new_parent.children(".children"));
    }

    isMinimized(block) {
        let isMinimized = block.data("minimized");
        if (typeof(isMinimized) === "undefined") {
            isMinimized = false;
        }
        return isMinimized;
    }

    minimizeBlock(block) {
        if (this.isMinimized(block)) {
            return;
        }
        block.children(".children").slideUp();
        block.children(".preview").slideUp();
        block.data("minimized", true);
        block.find("> .configurator > .config > .minimize").addClass("hidden");
        block.find("> .configurator > .config > .maximize").removeClass("hidden");
    }

    maximizeBlock(block) {
        if (!this.isMinimized(block)) {
            return;
        }
        block.children(".children").slideDown();
        block.children(".preview").slideDown();
        block.data("minimized", false);
        block.find("> .configurator > .config > .maximize").addClass("hidden");
        block.find("> .configurator > .config > .minimize").removeClass("hidden");

    }

    getBlockType(block) {
        return this.getDataValue(block, "block-name");
    }

    getBlock(element) {
        let block = element.closest(".pageit-block");
        if (block.length === 0) {
            return false;
        }
        return block;
    }

    getParentBlock(block, allowRoot = false) {
        let selector = ".pageit-block, .pageit-admin-editor";
        if (!allowRoot) {
            selector = ".pageit-block";
        }
        let parent = block.parent().parent(selector);
        if (parent.length === 0) {
            return false;
        }
        return parent;
    }

    getPreviousBlock(block) {
        let prev = block.prev();
        if (prev.length > 0) {
            return prev;
        }
        return false;
    }

    getNextBlock(block) {
        let next = block.next();
        if (next.length > 0) {
            return next;
        }
        return false;
    }

    getChildrenExposedOptions(block) {
        let exposed = {};
        for (let childBlock of this.getChildrenBlocks(block)) {
            let childExposure = this.getExposedOptions(childBlock);
            for (let key in childExposure) {
                if (key in config) {
                    // Either set by this block or explicitly renamed
                    continue;
                }
                // Passthrough
                exposed[key] = childExposure[key];
            }
        }
        return exposed;
    }

    getExposedOptions(block) {
        // Returns the names and specifications of all options that are configured to be
        // exposed by this block or one of its children.
        let block_type = this.getBlockType(block);

        let exposed = {};
        let spec = this.buildingBlocks[block_type]["configuration_options"];        
        // Own exposed options
        let config = this.getData(block);
        for (let key in config) {
            let option = config[key];
            if (option["is_exposed"]) {
                let exposed_name = option["exposed_name"];
                let option_spec = spec[key];
                exposed[exposed_name] = option_spec;
            }
        }
        // Children
        for (let childBlock of this.getChildrenBlocks(block)) {
            let childExposure = this.getExposedOptions(childBlock);
            for (let key in childExposure) {
                if (key in config) {
                    // Either set by this block or explicitly renamed
                    continue;
                }
                // Passthrough
                exposed[key] = childExposure[key];
            }
        }
        return exposed;
    }

    fillExposedOptions(block) {
        // TODO
        let parent = this.getParentBlock(block);
        if (parent !== false) {

        }
    }

    getChildrenBlocks(block) {
        let children = [];
        block.children(".children").children().each(function() {
            children.push($(this));
        });
        return children;
    }

    allowsChildren(block) {
        return block.children(".children").length !== 0;
    }

    showOverlay(title, content, enable_close=true) {
        let html = `        
        <div class="overlay-wrapper">
            <div class="overlay-header">
                <div class="title">
                    ${title}
                </div>
                <div class="close">
                    <i class="material-icons">close</i>
                </div>
            </div>
            <div class="overlay-content">
                ${content}
            </div>            
        </div>            
        `;
        let that = this;
        this.overlay.html(html);
        this.overlay.off("click", ".close");
        if (enable_close) {
            this.overlay.on("click", ".close", function() {
                that.hideOverlay();
            });                
            $("body").off("keydown", false, this.escapeHandlerFunction);
            $("body").on("keydown", false, this.escapeHandlerFunction)
        } else {
            this.overlay.find(".close").remove();
        }
        $("body").css("overflow-y", "hidden");
        this.overlay.show();
    }

    hideOverlay() {
        this.overlay.hide();
        $("body").css("overflow-y", "auto");
    }

    loadAvailableBlocks(callback) {
        let that = this;
        $.ajax({
            url: "api/layouts/get_blocks",
            type: "GET",
            data: {},
            success: function(response) {
                that.buildingBlocks = response.building_blocks;
                that.sortedBuildingBlocks = [];  
                let group_keys = {};
                for (let block_name in that.buildingBlocks) {
                    that.sortedBuildingBlocks.push(block_name);
                    let group_key = that.buildingBlocks[block_name].group_key;
                    let group_name = that.buildingBlocks[block_name].group;
                    if (group_name in group_keys) {
                        group_keys[group_name] = Math.min(group_key, group_keys[group_name]);
                    } else {
                        group_keys[group_name] = group_key;
                    }
                }

                let group_sort = function(a, b) {
                    let bb_a = that.buildingBlocks[a];
                    let bb_b = that.buildingBlocks[b];
                    if (group_keys[bb_a.group] === group_keys[bb_b.group]) {
                        if (bb_a.group === bb_b.group) {
                            return bb_a.sort_key > bb_b.sort_key ? 1 : bb_a.sort_key < bb_b.sort_key ? -1 : 0;    
                        } else {
                            return bb_a.group.localeCompare(bb_b.group);
                        }
                    }
                    return group_keys[bb_a.group] > group_keys[bb_b.group] ? 1 : group_keys[bb_a.group] < group_keys[bb_b.group] ? -1 : 0;
                }

                that.sortedBuildingBlocks = that.sortedBuildingBlocks.sort(group_sort);

                if (typeof(callback) !== undefined) {
                    callback();
                }
            },
            error: function() {
                let n = new Notification();
                n.error("Bausteine konnten nicht geladen werden");
            }
        });
    }

    showFullPreview() {
        let config = this.export();
        let that = this;
        let dlg = new Dialog();
        dlg.loading("Vorschau", "Vorschau wird geladen...");
        $.ajax({
            "url": "api/layouts/render",
            "type": "post",
            "data": {
                "post_class": this.postPreviewClass,
                "config": config
            },
            success: function(response) {
                let render = response;
                dlg.close();
                
                let iframeId = `${that.editorId}-preview-iframe`;
                let iframe = `<iframe style="width: 100%; height: calc(100% - 10px); border: 0; overflow-y: scroll;" id="${iframeId}" src="javascript:void(0);"></iframe>`;

                that.showOverlay("Vorschau", iframe);
                that.overlay.find(".overlay-content").addClass("nopad");

                let doc = document.getElementById(iframeId).contentWindow.document;
                doc.write(render);
                doc.close();
            },
            error: function() {
                dlg.close();
                new Notification().error("Vorschau konnte nicht geladen werden");
            }
        })
    }
}

$(function() {
    $(".pageit-editor").each(function() {
        let options = $(this).data();
        let editor = new PostEditor($(this), null, options);
    });
});