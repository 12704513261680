function PostsAdmin() {
	this.title = "";
	this.slug = "";
	this.manualSlug = false;
    this.content = "";
    this.published = false;
    this.sortdate = false;
    this.enddate = false;
    this.id = $("#posts_id").attr("postid");
	if (this.id != "-1") {
		this.manualSlug = true;
	}
}

PostsAdmin.prototype = {
	constructor: PostsAdmin,
	setTitle: function(title) {
		this.title = title;
		this.generateSlug();
	},
	generateSlug: function() {
		if (this.manualSlug) {
			return;
		}
		this.slug = getSlug(this.title, {
			lang: "de"
		});
		this.displaySlug();
	},
	setSlug: function(slug) {
		this.slug = slug;
		if (slug.length > 0) {
			this.manualSlug = true;
		} else { 
			this.manualSlug = false;
		}
	},
    setContent: function(content) {
        this.content = content;
    },
    setPublished: function(published) {
        this.published = published;
    },
    setSort: function(sort) {
        this.sortdate = sort;
    },
    setEnd: function(val) {
        this.enddate = val;
    },
	displaySlug: function() {
		$("#posts_slug").val(this.slug);
	},
    save: function(data, callback) {
        $.ajax({
            url: "admin/posts/api/save",
            method: "POST",
            data: {
                data: data
            },
            success: function(response) {
                callback(response);
            },
            error: function() {
                callback(false);
            }
        });
    },
    trash: function(onSuccess, onError) {
        $.ajax({
            url: "admin/posts/api/trash",
            method: "POST",
            data: {
                "id": this.id
            },
            success: function(response) {
                onSuccess();
            },
            error: function() {
                onError();
            }
        });
    },
    publish: function(onSuccess, onError) {
        $.ajax({
            url: "admin/posts/api/publish",
            method: "POST",
            data: {
                "id": this.id
            },
            success: function(response) {
                onSuccess();
            },
            error: function() {
                onError();
            }
        });
    },
    draft: function(onSuccess, onError) {
        $.ajax({
            url: "admin/posts/api/draft",
            method: "POST",
            data: {
                "id": this.id
            },
            success: function(response) {
                onSuccess();
            },
            error: function() {
                onError();
            }
        });
    },
    delete: function(onSuccess, onError) {
        $.ajax({
            url: "admin/posts/api/delete",
            method: "POST",
            data: {
                "id": this.id
            },
            success: function(response) {
                onSuccess();
            },
            error: function() {
                onError();
            }
        });
    },
    getData: function() {
        var data = {};
        data.id = $("#posts_id").attr("postid");

        data.title = $("#posts_title").val();
        data.slug  = $("#posts_slug").val();
        data.content = JSON.stringify(PostEditor.getInstance("#pageit-editor").export());
        data.publish = $("input[name=admin_posts_publish]:checked").val();
        data.publish_date = $("#admin_posts_publish_time_date").val();
        data.publish_time = $("#admin_posts_publish_time_time").val();
        
        data.sorttype = $("input[name=admin_posts_sorttype]:checked").val();
        data.sort_date = $("#admin_posts_sort_time_date").val();
        data.sort_time = $("#admin_posts_sort_time_time").val();
        
        data.endtype = $("input[name=admin_posts_endtype]:checked").val();
        data.end_date = $("#admin_posts_end_time_date").val();
        data.end_time = $("#admin_posts_end_time_time").val();
        
        data.comments = $("#posts_comments").prop("checked") ? "opened" : "closed";
		data.display = $("input[name=admin_posts_display]:checked").val();

        data.categories = $("#post_categories").val();        
        if (data.categories === null) {
            data.categories = [];
        }
        return data;
    }
}


$(document).ready(function() {
    if (typeof M === "undefined") {
        return;
    }
    
    var pickers = $(".admin_posts_datepicker");
    M.Datepicker.init(pickers, {
        format: 'dd.mm.yyyy'
    });

    $(".admin_posts_timepicker")
        .timepicker({
            format: "HH:mm",
            twelveHour: false            
        });
});

var postAdmin = new PostsAdmin();

// Add
$("#posts_title").on("input", function() {
    postAdmin.setTitle($(this).val());
});
$("#posts_slug").on("input", function() {
    postAdmin.setSlug($(this).val());
});


// Trash 
$("body").on("click", ".admin_posts_action_trash", function() {
    var id = $(this).attr("post");
    var that = this;
    postAdmin.id = id;
    postAdmin.trash(function() {
        // On Success
        var notify = new Notification();
        notify.success("Post in den Papierkorb verschoben");
        // Delete table Row
        $(that).closest("tr").fadeOut("fast", function() {
            $(that).closest("tr").remove();
        });
    }, function() {
        // On Error
        var notify = new Notification();
        notify.error("Post konnte nicht in den Papierkorb verschoben werden");
    });
});

// Publish
$("body").on("click", ".admin_posts_action_publish", function() {
    var id = $(this).attr("post");
    var location = $(this).attr("location");
    var that = this;
    postAdmin.id = id;
    postAdmin.publish(function() {
        // On Success
        var notify = new Notification();
        notify.success("Post wurde veröffentlicht");
        if (location == "start") {
            $("#admin_posts_action_publish_"+id).hide();
            $("#admin_posts_text_draft_"+id).hide();
            $("#admin_posts_action_draft_"+id).show();
        } else if (location == "trash") {
            // Delete table Row
            $(that).closest("tr").fadeOut("fast", function() {
                $(that).closest("tr").remove();
            });
        }        
    }, function() {
        // On Error
        var notify = new Notification();
        notify.error("Post konnte nicht veröffentlicht werden");
    });
});

// Draft
$("body").on("click", ".admin_posts_action_draft", function() {
    var id = $(this).attr("post");
    var location = $(this).attr("location");
    var that = this;
    postAdmin.id = id;
    postAdmin.draft(function() {
        // On Success
        var notify = new Notification();
        notify.success("Post wurde als Entwurf markiert");
        if (location == "start") {
            $("#admin_posts_action_publish_"+id).show();
            $("#admin_posts_text_draft_"+id).show();
            $("#admin_posts_action_draft_"+id).hide();
        } else if (location == "trash") {
            // Delete table Row
            $(that).closest("tr").fadeOut("fast", function() {
                $(that).closest("tr").remove();
            });
        }  
    }, function() {
        // On Error
        var notify = new Notification();
        notify.error("Post konnte nicht als Entwurf markiert werden");
    });
});

// Delete Page (Forever!)
$("body").on("click", ".admin_posts_action_delete", function() {
    var id = $(this).attr("post");
    var location = $(this).attr("location");
    var that = this;

    var dlg = new Dialog();
    dlg.confirm(
        "Wirklich löschen?",
        `Wollen Sie diesen Post und alle damit verbundenen Daten unwiderruflich löschen?`,
        "Ja",
        "Nein",
        function(res) {
            if (res) {
                postAdmin.id = id;
                postAdmin.delete(function() {
                    // On Success
                    var notify = new Notification();
                    notify.success("Post wurde gelöscht");
                    // Delete table Row
                    $(that).closest("tr").fadeOut("fast", function() {
                        $(that).closest("tr").remove();
                    });
                }, function() {
                    // On Error
                    var notify = new Notification();
                    notify.error("Post konnte nicht gelöscht werden");
                });
            }
        }
    )
    
});

// Bulk Select
$("body").on("change", "#posts_select_all", function() {
    var checked = $(this).prop("checked");
    $(".posts_select").prop("checked", checked);
});

// Bulk Actions
// Trash delete
$("body").on("click", "#post_trash_delete", function() {
    var dlg = new Dialog();
    dlg.confirm(
        "Wirklich löschen?",
        `Wollen Sie alle markierten Posts und alle damit verbundenen Daten unwiderruflich löschen?`,
        "Ja",
        "Nein",
        function(res) {
            if (res) {
                $(".posts_select:checked").each(function() {
                    var that = this;
                    postAdmin.id = $(this).attr("postid");
                    postAdmin.delete(function() {
                        // On Success
                        var notify = new Notification();
                        notify.success("Post wurde gelöscht");
                        // Delete table Row
                        $(that).closest("tr").fadeOut("fast", function() {
                            $(that).closest("tr").remove();
                        });
                    }, function() {
                        // On Error
                        var notify = new Notification();
                        notify.error("Post konnte nicht gelöscht werden");
                    });
                });                
            }
        }
    )
});
// Trash Restore
$("body").on("click", "#post_trash_restore", function() {    
    $(".posts_select:checked").each(function() {
        var that = this;
        postAdmin.id = $(this).attr("postid");
        postAdmin.publish(function() {
            // On Success
            var notify = new Notification();
            notify.success("Post wurde wiederhergestellt");
            // Delete table Row
            $(that).closest("tr").fadeOut("fast", function() {
                $(that).closest("tr").remove();
            });
        }, function() {
            // On Error
            var notify = new Notification();
            notify.error("Post konnte nicht wiederhergestellt werden");
        });
    });
});
// Post to Trash
$("body").on("click", "#post_move_to_trash", function() {    
    $(".posts_select:checked").each(function() {
        var that = this;
        postAdmin.id = $(this).attr("postid");
        postAdmin.trash(function() {
            // On Success
            var notify = new Notification();
            notify.success("Post wurde in den Papierkorb verschoben");
            // Delete table Row
            $(that).closest("tr").fadeOut("fast", function() {
                $(that).closest("tr").remove();
            });
        }, function() {
            // On Error
            var notify = new Notification();
            notify.error("Post konnte nicht in den Papierkorb verschoben werden");
        });
    });
});


$('.admin_input_duration').timeDurationPicker({
    onselect: function(element, seconds, humanDuration) {
        element.val(humanDuration);
        element.attr("seconds", seconds);
    },
    onInit: function(picker, element) {
        var seconds = element.attr("seconds");
        picker.setDuration(seconds);
        element.val(picker.getHumanDuration());
    }
});

// INIT PRESETS
$(function() {
    // PUBLISH
    var preset = $("#admin_posts_publish_preset").attr("preset");
    $("#admin_posts_publish_"+preset).click();
    // SORT
    var preset = $("#admin_posts_sort_preset").attr("preset");
    $("#admin_posts_sorttype_"+preset).click();
    // END
    var preset = $("#admin_posts_end_preset").attr("preset");
    $("#admin_posts_endtype_"+preset).click();

    // COMMENTS
    var comments = $("#posts_comments").attr("preset");
    if (comments) {
        $("#posts_comments").click();
    }
    // DISPLAY
    var display = $("#admin_posts_display_preset").attr("preset");
    $("input[name=admin_posts_display][value=" + display + "]").click();
});

// Publish-Times
$("body").on("change", "input[type=radio][name=admin_posts_publish]", function() {
    if ($(this).val() == "time") {
        $("#admin_posts_publish_time_settings").slideDown("fast");
    } else {
        $("#admin_posts_publish_time_settings").slideUp("fast");        
    }
});

// Sort-Times
$("body").on("change", "input[type=radio][name=admin_posts_sorttype]", function() {
    if ($(this).val() == "custom") {
        $("#admin_posts_sort_time_settings").slideDown("fast");
    } else {
        $("#admin_posts_sort_time_settings").slideUp("fast");        
    }
});
// End-Times
$("body").on("change", "input[type=radio][name=admin_posts_endtype]", function() {
    if ($(this).val() == "custom") {
        $("#admin_posts_end_time_settings").slideDown("fast");
    } else {
        $("#admin_posts_end_time_settings").slideUp("fast");        
    }
});

// Create New Post
$("#posts_save").click(function() {
    var data = postAdmin.getData();

    if (data.title == "" || data.slug == "") {
        var notify = new Notification();
        notify.error("Titel und Pfad dürfen nicht leer sein!");
        return;
    }

    var loading = new Dialog();
    loading.loading(
        "Post wird gespeichert",
        "Bitte warten Sie, während der Post gespeichert wird..."
    );

    postAdmin.save(data, function(result) {
        loading.close();
        var notify = new Notification();
        if (!result) {
            if (data.id === "-1") {
                notify.error("Post konnte nicht erstellt werden.");
            } else {
                notify.error("Post konnte nicht bearbeitet werden.");
            }
        } else {
            PostEditor.getInstance("#pageit-editor").saved();
            if (data.id === "-1") {
                notify.success("Post wurde erstellt. Sie werden weitergeleitet.");
                $("#posts_save").hide();
                setTimeout(function() {
                    location.href = "admin/posts/edit/" + result.id;
                }, 2000);
            } else {
                notify.success("Post wurde gespeichert.");
            }
        }
    });
});


// Create New Post
$("#posts_trash").click(function() {
    var data = postAdmin.getData();
    if (data.id === "-1") {
        return false;
    }
    postAdmin.trash(function() {
        // On Success
        var notify = new Notification();
        notify.success("Post wurde in den Papierkorb verschoben");
        location.href = "admin/posts";
    }, function() {
        // On Error
        var notify = new Notification();
        notify.error("Post konnte nicht in den Papierkorb verschoben werden");
    });
});